import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";

export default function Booklist() {
  const [currentGrade, setCurrentGrade] = useState(null);
  const [currentBooks, setCurrentBooks] = useState(null);

  useEffect(() => {
    setCurrentGrade("10");

    const currentBooksFilter = allGradeBooks.filter(
      (cBooksFilter) => cBooksFilter.Grade === uniqGrades[uniqGrades.length - 1]
    );

    setCurrentBooks(currentBooksFilter);
  }, []);

  const data = useStaticQuery(graphql`
    {
      allBooklistBloom2079Csv {
        nodes {
          Publication
          Subject
          Grade
          SN
        }
      }
    }
  `);

  const allGradeBooks = data.allBooklistBloom2079Csv.nodes;

  // console.log("All Grade Books", allGradeBooks);

  const listOfAllgrades = allGradeBooks.map((gradeM) => gradeM.Grade);
  let uniqGrades = [];
  listOfAllgrades.forEach((_) => {
    if (!(_ in uniqGrades)) {
      uniqGrades.push(_);
      // console.log(_);
    } else {
      // console.log("Not printing this!", _);
    }
  });
  uniqGrades.splice(0, 1);

  return (
    <div>
      <div className="md:flex flex-wrap md:justify-center">
        {uniqGrades.map((_) => (
          <button
            key={_}
            className={`md:block button-outline py-1 px-2 bg-gray-600 text-white lg:w-1/4 my-1.5 m-2 ${
              currentGrade === _ ? "bg-red-800" : ""
            }`}
            onClick={() => {
              setCurrentGrade(_);

              const currentBooksFilter = allGradeBooks.filter(
                (cBooksFilter) => cBooksFilter.Grade === _
              );
              // console.log("Books", _);
              setCurrentBooks(currentBooksFilter);
            }}
          >
            {" "}
            Grade {_}{" "}
          </button>
        ))}
      </div>

      <div className="justify-center">
        <CurrentGradeBookList
          booksforCurrentlySelectedGrade={currentBooks}
          currentlySelectedGrade={currentGrade}
        />
      </div>
    </div>
  );
}

const CurrentGradeBookList = (props) => {
  const currentBooks = props.booksforCurrentlySelectedGrade;
  // console.log("This is the final list", currentBooks);
  return (
    <div>
      {currentBooks === null ? null : (
        <table
          className="mt-4 md:mt-8 lg:mt-12 formatted-table border-collapse"
          style={{ borderCollapse: "collapse" }}
        >
          <thead className="font-medium">
            <tr className="hover:bg-red-800 hover:text-white md:text-xl">
              <th className="font-medium">SN</th>
              <th className="font-medium"> Subject </th>
              <th className="font-medium">Publication </th>
            </tr>
          </thead>
          <tbody>
            {currentBooks.map((_) => (
              <tr>
                <td>{_.SN}</td>
                <td>{_.Subject}</td>
                <td>{_.Publication}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
