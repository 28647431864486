import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/seo";
import Booklist from "../components/booklist";

const AchievingMission = () => {
  const [activeActivity, setActiveActivity] = useState(0);
  useEffect(() => {
    document
      .querySelector("#activitiesTowardMission")
      .childNodes.forEach((node) => {
        node.style = "color: initial; background: initial;";
      });
    document.querySelector("#activitiesTowardMission").childNodes[
      activeActivity
    ].style = "background-color: #742a2a; color: white; border: white";
  });

  const handleChange = (event) => {
    const newIndex = parseInt(event.currentTarget.dataset.index);
    setActiveActivity(newIndex);
  };
  const CurrentActivityDetails = ({ activeActivityIndex }) => {
    if (activeActivityIndex === 0) {
      return (
        <div className="text-base">
          <h3 className="section-head text-xl text-red-800">
            DEAR Culture at Bloom
          </h3>
          <p className="mt-2">
            DEAR stands for Drop Everything And Read. Every Friday, students at
            Bloom have an hour of DEAR time where they study their favorite
            Novel, Newspaper article or anything that’s{" "}
            <span className="font-medium">not</span> a part of their school
            curriculum.
          </p>
          <p className="mt-2">
            With the implementation of DEAR culture, we have seen significant
            improvement in students' reading habits.
          </p>
        </div>
      );
    } else if (activeActivityIndex === 1) {
      return (
        <div className="text-base">
          <h3 className="section-head text-xl text-red-800">
            House of the Month
          </h3>
          <p className="mt-2">
            Each month, parallel houses compete for the House of the Month
            trophy. The winning house is decided based on the month-long
            cleanliness evaluation of the houses.
          </p>
          <p className="mt-2">
            After the announcement of the winner in the school assembly, the
            winning house comes together in a triumphant celebratory mood (often
            reminding one how even the small things in life can give so much
            happiness) and the other house comes together to prepare for the
            victory and seize the trophy next month.
          </p>
          <p className="mt-2">
            This program was initiated in Bloom after seeing from the success it
            had in Budhanilkantha School. At Bloom, students have taught us more
            through this program than we tried to teach our students through it.
          </p>
        </div>
      );
    } else if (activeActivityIndex === 2) {
      return (
        <div className="text-base">
          <h3 className="section-head text-xl text-red-800">
            LML Article Of The Week
          </h3>
          <p className="mt-2">
            Every Friday evening, students sit for an hour to write an article
            on one of the themes provided by the LML Club. Day Scholars bring
            their articles on Sunday. Best articles are awarded a good chocolate
            treat and a fancy certificate from the LML club.
          </p>
          <p className="mt-2">
            LML Club sends the articles of the winners to the national daily
            newspapers. So far, many of our students' writings have appeared in
            the top national newspapers like Kathmandu Post and Republica.
          </p>
        </div>
      );
    }
  };

  return (
    <div className="section-for-small-devices">
      <h2 className="section-head ">Achieving Our Mission</h2>
      <p className="mt-2 lg:mt-8 lg:pr-16">
        We are always experimenting with various policies, putting forward
        programs and testing their effectiveness in achieving our mission. Here
        are some of the things are doing.
      </p>
      <p className="mt-6 text-sm  lg:hidden">
        All programs listed below are openly licensed. You may implement them
        freely at your school.
      </p>
      <div className="lg:flex mt-8">
        <div
          id="activitiesTowardMission"
          className="flex-col mt-4 justify-items-start lg:w-2/5 lg:pr-8 text-base"
        >
          <button
            data-index="0"
            onClick={handleChange}
            className="block py-4 px-8 mt-4 w-full button-outline border-2 border-blue-900  hover:border-transparent"
          >
            <h3 className="font-medium"> DEAR Time</h3>
            <h4 className="text-sm">Cultivating a Reading Culture</h4>
          </button>
          <button
            data-index="1"
            onClick={handleChange}
            className="block py-4 px-8 mt-4 w-full button-outline border-2 border-blue-900 hover:border-transparent"
          >
            <h3 className="font-medium">House Of The Month</h3>
            <h4 className="text-sm">Creating a Sense of Healthy Competition</h4>
          </button>
          <button
            data-index="2"
            onClick={handleChange}
            className="block py-4 px-8 mt-4 w-full button-outline border-2 border-blue-900 hover:border-transparent"
          >
            <h3 className="font-medium">LML Article Of The Week</h3>
            <h4 className="text-sm">
              Emphasizing the importance of literature
            </h4>
          </button>
          <p className="mt-8 hidden text-sm lg:block">
            All programs listed above are openly licensed. You may implement
            them freely at your school.
          </p>
        </div>
        <div className="mt-12 lg:mt-8 lg:w-3/5 lg:px-8">
          <CurrentActivityDetails activeActivityIndex={activeActivity} />
        </div>
      </div>
      <Link
        to="/events"
        className="text-center block lg:inline-block lg:mt-0 mt-8"
      ></Link>
    </div>
  );
};

export const data = graphql`
  query {
    studentsFromBack: file(relativePath: { eq: "group-on-back.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    studentsOnSite: file(relativePath: { eq: "students-on-site.jpg" }) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          src
          srcSet
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default function Stories({ data }) {
  const studentsFromBack = data.studentsFromBack.childImageSharp.fluid;
  const studentsOnSite = data.studentsOnSite.childImageSharp.fluid;
  return (
    <Layout>
      <SEO title="Academics" />

      <section className="pb-8 lg:border-r lg:border-b md:border-gray-600 md:py-16">
        <div className="section-for-small-devices lg:flex">
          <div className="lg:w-1/2 lg:pr-8 mt-8">
            <h2 className="section-head md:text-6xl text-3xl leading-none text-left">
              <span className="md:text-6xl text-3xl text-red-800">Book-</span>
              List
            </h2>
            <p className="mt-4 md:mt-8">
              Please click on the appropriate grade to view your desired
              booklist for the year 2079 BS .
            </p>

            <p className="mt-2">
              Both Lubhoo and Itahari School use the same booklist.
            </p>
          </div>
          <div className="my-2">
            <Booklist />
          </div>
        </div>
      </section>

      <section className="lg:border-l lg:border-b border-gray-600">
        <AchievingMission />
      </section>
      <section className="pb-16 lg:border-r lg:border-b md:border-gray-600 md:py-16">
        <div className="section-for-small-devices lg:flex">
          <div className="lg:w-1/2 lg:pr-8 mt-8">
            <h2 className="section-head md:text-6xl text-3xl leading-none text-left">
              <span className="md:text-6xl text-3xl text-red-800">Hands-</span>
              On
            </h2>
            <div className="mt-4 lg:hidden section-image-fix">
              <Img
                className="mt-4"
                fluid={studentsOnSite}
                alt="two boys students collecting data in what looks like a cultural hertiage"
                draggable={false}
              />
            </div>
            <p className="mt-4 md:mt-8">
              We encourage students to go out onto the field and get their hands
              dirty. This, we believe, is the only way to really learn.
            </p>
            <p className="mt-2">
              Be it for a Math/Science class or Social Studies, we maximize the
              use of in-class and out-of-the-class activities for students to
              engage in and enjoy the topic.
            </p>
          </div>
          <div className="hidden lg:block lg:w-1/2 lg:pl-8">
            <Img
              className="mt-4"
              fluid={studentsOnSite}
              alt="students standing"
              draggable={false}
            />
          </div>
        </div>
      </section>
      {/* <section className="h-screen my-auto flex justify-center items-center">
        <h1 className="text-3xl md:text-6xl text-red-700 section-head">
          That's all in academics?
        </h1>
      </section> */}

      <section className="pb-16 lg:border-r lg:border-b md:border-gray-600 md:py-16">
        <div className="section-for-small-devices lg:flex">
          <div className="lg:w-1/2 lg:pr-8 mt-8">
            <h2 className="section-head md:text-6xl text-3xl leading-none text-left">
              Passion{" "}
              <span className="md:text-6xl text-3xl text-red-800">First</span>
            </h2>
            <div className="mt-4 lg:hidden section-image-fix">
              <Img
                className="mt-4"
                fluid={studentsFromBack}
                alt="students standing"
                draggable={false}
              />
            </div>
            <p className="mt-4 md:mt-8">
              We deeply believe that passion is a major driving component of
              one's success in life.
            </p>
            <p className="mt-2">
              Bloom Nepal is merely an institution with the right set of tools
              and an environment for fostering and nurturing each child's
              passion.
            </p>
          </div>
          <div className="hidden lg:block lg:w-1/2 lg:pl-8">
            <Img
              className="mt-4"
              fluid={studentsFromBack}
              alt="students standing"
              draggable={false}
            />
          </div>
        </div>
      </section>
    </Layout>
  );
}
